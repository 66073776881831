.subsystem-div {
  width: 80%;
  height: fit-content;
  margin-bottom: 5%;
}

.subsystem-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
