* {
  box-sizing: border-box;
}

body {
  background-color: #180b2f;
  margin: 0;
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}
